export default {
    'App Name': '遊戲啟動器',
    'Operator': '營運商',
    'Provider': '供應商',
    'Game': '遊戲',
    'Player': '玩家',
    'Play Host': '網址',
    'Socket Server': 'Socket 伺服器',
    'Language': '遊戲語言',
    'Pop Out': '彈出視窗',
    'Debug Mode': '除錯模式'
}