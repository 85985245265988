export default {
    'App Name': 'Game Launcher',
    'Operator': 'Operator',
    'Provider': 'Provider',
    'Game': 'Game',
    'Player': 'Player',
    'Play Host': 'Play Host',
    'Socket Server': 'Socket Socket Server',
    'Language': 'Language',
    'Pop Oup': 'Pop Oup',
    'Debug Mode': 'Debug Mode'
}