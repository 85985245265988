import to from 'await-to-js'
import axios from 'axios'

let timeout = process.env.VUE_APP_API_TIMEOUT * 1
const toAxios = (method) => async (options) => {
  const logHead = '[toAxios] '
  options = {
    ...options,
    method,
    timeout
  }
  const [err, res] = await to(axios(options))

  if (err) {
    if (!err.response) {
      throw err
    }
    const { status } = err.response
    if (status >= 400 && status <= 499) {
      // 從後台取得資料 err，如果是 client side error 直接登出
      console.log(logHead, err.response)
      // store.dispatch('auth/logout')
      return
    } else {
      // 如果是 server side error 直接返回 server
      throw err
    }
  }

  let myStatus = parseInt(res.status)
  if ( myStatus >= 200 && myStatus < 300) {
    const { data } = res.data
    return data
  } else {
    console.log(logHead, res)
    throw new Error(`${res.statusText}(${res.status})`)
  }
}

export default {
  get: toAxios('get'),
  put: toAxios('put'),
  delete: toAxios('delete'),
  post: toAxios('post')
}
