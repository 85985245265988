<script>
export default {
  data() {
    return {
      operatorList: [
        {
          id: 19641,
          name: "rgs官網",
          xOperator: "rgsdemoop",
          xKey: "0e6cc14c1f094fadb794ae2d8cf183fd",
        },
        {
          id: 29813,
          name: "越南營運測試",
          xOperator: "VN123_TEST",
          xKey: "c6e92a49b6ed4be0baccb80a86be1693",
        },
        {
          id: 14361,
          name: "揪吉麻將",
          xOperator: "just_mahjong",
          xKey: "91fa1568a5c1441992b3736e65bc7546",
        },
        {
          id: 106,
          name: "bx高級營運",
          xOperator: "bxvip",
          xKey: "0353cdbac32ad245a3c3b84f4434b314",
        },
      ],
      providerList: [
        {
          id: 4,
          name: "ATG",
        },
      ],
      gameClasses: [
        {
          name: "不分廳",
          value: 0,
        },
        {
          name: "娛樂廳",
          value: 1,
        },
        {
          name: "貴賓廳",
          value: 2,
        },
        {
          name: "豪華廳",
          value: 3,
        },
        {
          name: "尊爵廳",
          value: 4,
        },
      ],
      hostList: [
        {
          name: "play.riversense.tw",
          value: "play.riversense.tw",
        },
        {
          name: "play.game.bx",
          value: "play.game.bx",
        },
        {
          name: "192.168.40.2",
          value: "192.168.40.2",
        },
        {
          name: "127.0.0.1",
          value: "127.0.0.1",
        },
        {
          name: "localhost",
          value: "localhost",
        },
      ],
      socketList: [
        {
          name: "socket.riversense.tw",
          value: "socket.riversense.tw",
        },
        {
          name: "socket-poker.riversense.tw",
          value: "socket-poker.riversense.tw",
        },
        {
          name: "socket-lottery.riversense.tw",
          value: "socket-lottery.riversense.tw",
        },
        {
          name: "fungame-socket.riversense.tw",
          value: "fungame-socket.riversense.tw",
        },
        {
          name: "socket2022.riversense.tw",
          value: "socket2022.riversense.tw",
        },
        {
          name: "socket.game.bx",
          value: "socket.game.bx",
        },
        {
          name: "socket2.game.bx",
          value: "socket2.game.bx",
        },
        {
          name: "multiplayer.riversense.tw(Internet)",
          value: "multiplayer.riversense.tw",
        },
        {
          name: "multi.egame.rs",
          value: "multi.egame.rs",
        },
        {
          name: "192.168.40.2:13560",
          value: "192.168.40.2:13560",
        },
      ],
    };
  },
};
</script>
