/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import iView from 'view-design'
import VueI18n from 'vue-i18n'
import en from 'view-design/dist/locale/en-US'
import zh from 'view-design/dist/locale/zh-CN'
import 'view-design/dist/styles/iview.css'
import locales from '~l'
import Router from 'vue-router'

Vue.config.productionTip = false

axios.defaults.baseURL = `${process.env.VUE_APP_API}`
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT * 1

Vue.use(VueI18n)
Vue.use(iView)
Vue.use(Router)

Vue.locale = () => {}

const messages = {
  'en': Object.assign(locales.en, en),
  'zh-tw': Object.assign(locales.zhTw, zh)
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE || 'en', // set locale
  messages
})

const router = new Router({
  mode: 'history'
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
