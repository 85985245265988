<template lang="pug">
  #app
    #launcher
      Card(
        :dis-hover="true"
        :bordered="true"
        :shadow="true"
      )
        p.title(slot="title") {{ $t("App Name") }}
        .form
          Form(:model="formItem" :label-width="100")
            div(v-if="!queryGame")
              FormItem(:label="$t('Operator')")
                Select(
                  filterable
                  v-model="formItem.operator"
                  @on-change="changeOperator"
                )
                  Option(
                    v-for="item in operatorList"
                    :value="item.id"
                    :key="`operator-${item.id}`"
                    :label="`${item.name}(${item.xOperator})`"
                  ) {{ item.name }}

              FormItem(:label="$t('Provider')")
                Select(
                  filterable
                  v-model="formItem.provider"
                  @on-change="changeProvider"
                )
                  Option(
                    v-for="item in providerList"
                    :value="item.id"
                    :key="`provider-${item.id}`"
                  ) {{ item.name }}

            FormItem(:label="$t('Game')")
              Select(
                filterable
                v-model="formItem.game"
                @on-change="changeGame"
              )
                Avatar(slot="prefix" shape="square" size="small" :src="gameIcon")
                Option(
                  v-for="(item, i) in gameList"
                  :value="item.code"
                  :label="item.name"
                  :key="`game-${i}-${item.code}`"
                )
                  Avatar(shape="square" icon="ios-person" size="small" :src="item.url")
                  span.option-text {{ item.name }}({{ item.code }})
              
            FormItem(:label="$t('Player')")
              Select(
                filterable
                v-model="formItem.player"
              )
                Option(
                  v-for="(item, i) in playerList"
                  :value="item.username"
                  :key="`player-${i}-${item.id}`"
                ) {{ item.username }}

            FormItem(:label="$t('Play Host')")
              Select(
                filterable
                allow-create
                v-model="formItem.playHost"
                @on-create="createHost"
              )
                Option(
                  v-for="item in hostList"
                  :value="item.value"
                  :key="item.value"
                ) {{ item.name }}

            FormItem(:label="$t('Socket Server')")
              Select(
                filterable
                allow-create
                v-model="formItem.socketServer"
                @on-create="createSocket"
              )
                Option(
                  v-for="item in socketList"
                  :value="item.value"
                  :key="item.value"
                ) {{ item.name }}

            FormItem(:label="$t('Language')")
              Select(
                filterable
                v-model="formItem.language"
                @on-change="changeLanguage"
              )
                Option(
                  v-for="item in languageList"
                  :value="item.value"
                  :key="item.value"
                  :label="item.name"
                ) {{ item.name }}

              //- FormItem(:label="$t('Extra Path')")
              //-   Input(
              //-     v-model="formItem.extraPath"
              //-   )
            
            div(v-if="!queryGame")
              FormItem.text-right(:label="$t('Pop Out')")
                i-switch(
                  v-model="formItem.popOut"
                )

              FormItem.text-right(:label="$t('Debug Mode')")
                i-switch(
                  v-model="formItem.debugMode"
              )
        .footer
          .version v{{ version }}
          Button.launch-btn(
            type="primary"
            size="large"
            @click="playGame"
          ) Launch Game

</template>

<script>
import API from '~a'
import to from 'await-to-js'
import Configs from '~m/configs'

export default {
  name: 'App',
  components: {},
  mixins: [Configs],
  data () {
    return {
      token: null,
      tokenTimer: null,
      version: process.env.PACKAGE_VERSION,
      formItem: {
        provider: null,
        operator: null,
        game: '',
        player: '',
        language: '',
        playHost: '',
        socketServer: '',
        gameUrl: '',
        popOut: false,
        debugMode: false,
        extraPath: '',
        gameClass: 1
      },
      gameList: [],
      playerList: [],
      languageList: [
        {
          name: '繁體中文',
          value: 'zh-tw'
        },
        {
          name: '简体中文',
          value: 'zh-cn'
        },
        {
          name: 'English',
          value: 'en'
        },
        {
          name: 'Tiếng Việt',
          value: 'vn'
        },
      ],
    }
  },
  computed: {
    gameIcon () {
      const game = this.gameList.filter(g => g.code === this.formItem.game)
      if (game && game.length) {
        return game[0].url
      }
      return null
    },
    game () {
      const game = this.gameList.filter(g => g.code === this.formItem.game)
      if (game && game.length) {
        return game[0]
      }
      return null
    },
    queryGame () {
      return this.$route.query.game
    },
    queryLocale () {
      return this.$route.query.locale
    },
  },
  methods: {
    getRandomPlayer () {
      let username = null
      while (!username) {
        let rnd = Math.floor(Math.random() * this.playerList.length)
        username = this.playerList[rnd].username
        if (!/rsgtest[0-9]+/.test(username)) username = null
      }
      return username
    },
    createHost (query) {
      this.hostList.push({
        value: query,
        name: query
      })
      this.fromItem.playHost = query
    },
    createSocket (query) {
      this.socketList.push({
        value: query,
        name: query
      })
      this.fromItem.socketServer = query
    },
    async updateToken () {
      console.log(`update token start`)
      const operator = this.operatorList.filter(op => op.id === parseInt(this.formItem.operator))
      if (!operator.length) return console.log('No operator found')
      const { xOperator, xKey } = operator[0]
      let [err, data] = await to(API.get({
        url: '/token',
        headers: {
          'X-Operator': xOperator,
          'X-Key': xKey,
        }
      }))

      if (err) {
        console.log('Get token error', err)
      }

      const { token } = data

      this.token = token
      if (this.tokenTimer) clearInterval(this.tokenTimer)
      console.log(`update token end: ${token}`)
      this.tokenTimer = setTimeout(this.updateToken, 1 * 60 * 1000)
    },
    async getGame () {
      let url = `/games?provider=${this.formItem.provider}&rows=1000`
      if (this.queryGame) {
        url += `&locale=${this.formItem.language}`
      }
      let [err, data] = await to(API.get({
        url,
        headers: {
          'X-Token': this.token
        }
      }))

      if (err) {
        console.log('Get token error', err)
      }

      const { games } = data
      this.gameList = games
      if (this.queryGame) {
        this.formItem.game = this.queryGame
        this.updateQuery()
      } else {
        this.formItem.game = games[0].code
      }
    },
    async playGame () {
      let { provider, game, player } = this.formItem
      if (this.queryGame) player = this.getRandomPlayer()
      let [err, data] = await to(API.get({
        url: `/game-providers/${provider}/games/${game}/key?username=${player}`,
        headers: {
          'X-Token': this.token
        }
      }))

      if (err) {
        console.log('Get play token error', err)
      }
      const { key } = data
      let url = `/game-providers/${provider}/play?key=${key}`

      if (parseInt(this.formItem.operator) === 9847) url = `${url}&gameclass=${this.formItem.gameClass}`

      let [gerr, gdata] = await to(API.get({
        url,
        headers: {
          'X-Token': this.token
        }
      }))

      if (gerr) {
        console.log('Get play url error', gerr)
      }

      let mygame = this.gameList.filter(g => g.code === game)[0]
      // eslint-disable-next-line no-unused-vars
      let { viewMode, category } = mygame
      
      let { url: playUrl } = gdata

      let urlObj = new URL(playUrl)

      if (!/riversense\.tw/.test(this.formItem.playHost)) urlObj.protocol = 'http:'
      if (this.queryGame) {
        urlObj.host = 'play.riversense.tw'
        // switch (this.queryGame) {
        //   case 'bubble-gum':
        //     urlObj.searchParams.set('socket_url', 'multiplayer.riversense.tw')
        //     break
        //   default:
        //     urlObj.searchParams.set('socket_url', 'socket.riversense.tw')
        // }
      } else {
        urlObj.host = this.formItem.playHost
        urlObj.searchParams.set('socket_url', this.formItem.socketServer)
      }
      
      if (this.formItem.debugMode) urlObj.searchParams.set('debug', this.formItem.debugMode)
      urlObj.searchParams.set('l', this.formItem.language)
      viewMode = viewMode || urlObj.searchParams.get('view_mode')

      // this.formItem.extraPath = category === 'multiplayer' ? 'game' : ''
      // if (this.formItem.extraPath) urlObj.pathname = `${urlObj.pathname}${this.formItem.extraPath}/`
      
      playUrl = urlObj.toString()
      if (this.formItem.popOut || this.queryGame) { 
        let width = 414
        let height = 736
        if (viewMode === 'landscape') {
          width = 776
          height = 437
        }
        window.open(playUrl, '_blank', `location=no,width=${width},height=${height},scrollbars=no,status=no`)
      } else {
        window.open(playUrl)
      }
    },
    updateQuery () {
      this.$router.replace({ query: { game: this.formItem.game, locale: this.formItem.language || 'zh-tw' } })
    },
    changeGame () {
      if (this.queryGame) {
        this.updateQuery ()
      }
    },
    async changeLanguage () {
      if (this.queryGame) {
        this.updateQuery ()
        await this.getGame()
      }
    },
    async getPlayer () {
      let [err, data] = await to(API.get({
        url: `/players?rows=1000`,
        headers: {
          'X-Token': this.token
        }
      }))

      if (err) {
        console.log('Get token error', err)
      }
      const { players } = data
      this.playerList = players.sort((a,b) => a.id - b.id)
      if (this.queryGame) {
        const rnd = Math.round(Math.random() * players.length)
        this.formItem.player = players[rnd].username
      } else {
        this.formItem.player = players[0].username
      }
    },
    async reload () {
      console.log(`Start reload...`)
      await this.updateToken()
      await this.getGame()
      await this.getPlayer()
      console.log(`End reload...`)
    },
    async changeProvider () {
      console.log(`Start reload...`)
      // await this.updateToken()
      this.formItem.game = null
      await this.getGame()
      console.log(`End reload...`)
    },
    async changeOperator () {
      console.log(`Start reload...`)
      await this.updateToken()
      this.formItem.player = null
      await this.getPlayer()
      console.log(`End reload...`)
    },
  },
  async mounted () {
    if (!this.formItem.provider) this.formItem.provider = 4
    if (!this.formItem.operator) this.formItem.operator = 106
    if (this.queryGame) this.formItem.game = this.queryGame
    if (this.queryLocale) {
      this.formItem.language = this.queryLocale
    }

    if (!this.formItem.language) {
      this.formItem.language = this.languageList[0].value
    }

    if (!this.formItem.playHost) this.formItem.playHost = this.hostList[0].value
    if (!this.formItem.socketServer) this.formItem.socketServer = this.socketList[0].value
    this.reload()
  }
}
</script>

<style lang="sass">
html, body
  // margin: 60px
  // height: 100%
  background-color: #eee

.option-text
  color: #666
  padding: 0 8px

#app
  height: 100%
  // height: 100%
  font-family: Avenir, Helvetica, Arial, sans-serif
  smoothing: always
  color: #2c3e50
  background-color: #eee
  overflow: auto
  
  #launcher
    width: 500px
    position: absolute
    left: 50%
    top: 50%
    margin-left: -250px
    transform: translateY(-60%)
    width: 500px

    .ivu-card-body
      padding: 36px

    .footer
      padding: 0
      width: 100%
      display: flex
      flex-direction: row
      justify-content: flex-end
      .launch-btn
    
    .text-right
      text-align: right

  .version
    left: 16px
    bottom: 8px
    position: absolute
    margin: 8px
    text-align: right
    color: #ccc
</style>
